import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Offers API webhooks`}</h2>
    <h3>{`Which webhooks should I listen to when using the Offers API?`}</h3>
    <p>{`The Offers API utilises certain events which fire each time a transaction qualifies for an offer created in your account. These are as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`transaction.auth.qualified`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`transaction.clearing.qualified`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`transaction.refund.qualified`}</strong></p>
      </li>
    </ul>
    <p>{`As referred to above each event will only fire should the transaction meet an offer criteria.`}</p>
    <p>{`Given this example: the account contains active offer contains a schedule with a 10% discount off purchases made Monday to Friday. If a transaction tracks on a Saturday then this would fall outside of the pre-determined schedule with no message being sent to you.`}</p>
    <p>{`An added nuance can come into play where you decide to also listen to the core transaction webhooks which are:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`transaction.auth`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`transaction.clearing`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`transaction.refund`}</strong></p>
      </li>
    </ul>
    <p>{`These events will not listen to offers qualified within the account, therefore will always trigger should an enrolled card purchase at an onboarded location.`}</p>
    <p>{`You may wish to listen to both sets if, for example, you are running multiple offers for each brand, such as having an always-on offer in tandem with a more defined one.`}</p>
    <p>{`The key factor here is for the client to build additional logic on their side in terms of recognising when they should reward on a message received from Fidel API.`}</p>
    <h2>{`Offer charges`}</h2>
    <h3>{`Will I still be charged for a transaction that has not qualified for an offer that I have created in the platform?`}</h3>
    <p>{`Fidel is charged for each transaction that is sent to us by the card schemes irrespective of whether that transaction has qualified or not. Therefore, the charge will still be applicable to you regardless of what your webhook setup is i.e. if you are only listening to the offers webhooks and don't receive the data to your application.`}</p>
    <h2>{`Offer data in webhook`}</h2>
    <h3>{`At what point does ‘offer’ data become available in the refund processing webhook?`}</h3>
    <p>{`The presence of the “offer” data depends on the webhook being used. If the you are referring to the `}<code>{`transaction.refund.qualified`}</code>{` webhook, this should always include the “offer” object in the refund transaction, as this webhook is triggered by refund transactions done on locations linked to offers. However, if the webhook in question is transaction.refund, this one does not require that the refund is connected to an offer, meaning it may or may not contain the “offer” object, even if the location where the refund was done is connected to an offer. As for the “originalTransactionID”, it is included in refund notifications when we can identify the original transaction associated with the refund, we cannot guarantee the match of these transactions because most of the cases we do not receive information matching original transactions from the card networks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      